@use 'sass:color';

$accent-colour: #b2b2b2;
$secondary-colour: #ffcb00;

$colour-accent-active: #eeeeee;
$colour-accent-hover: darken($colour-accent-active, 0.2);
$colour-accent-selected: $colour-accent-active;

$link-colour: black;
$link-text-colour: #595959;
$button-text-colour: black;
$heading-text-colour: black;
$body-text-colour: #3a3b3c;
$header-text-colour: white;
$sidebar-colour: white;

$body-font-family: 'Roboto', 'Segoe', 'Candara', 'Calibri', 'Arial', sans-serif;
$heading-font-family: 'Oswald', 'Segoe', 'Candara', 'Calibri', 'Arial', sans-serif;

@use '~@cognitranlimited/itis-web/dist/styles' with (
    $colour-accent: $accent-colour,
    $heading-text-colour: black,
    $body-font-family: $body-font-family,
    $body-text-colour: $body-text-colour,
    $Link-text-colour: $link-colour,
    $heading-font-family: $heading-font-family,
    $heading-font-weight: 400,
    $Button-text-colour: $button-text-colour,
    $Button-text-colour-disabled: rgba(0, 0, 0, 0.26),
    $Button-background-disabled: rgba(0, 0, 0, 0.12),
    $Button-background: $secondary-colour,
    $Button-background-hover: black,
    $Button-background-active: lighten(black, 15),
    $Button-text-colour-hover: white,
    $Button-text-colour-danger: white,
    $Button-text-colour-outlined: $button-text-colour,
    $Button-border-outlined: 2px solid $button-text-colour,
    $Button-text-colour-outlined-hover: white,
    $Button-background-outlined-hover: $button-text-colour,
    $Button-text-colour-outlined-active: white,
    $Button-background-outlined-active: lighten($button-text-colour, 15),
    $Button-border-colour-outlined-active: $button-text-colour,
    $Sidebar-text-colour: $heading-text-colour,
    $Sidebar-background: $sidebar-colour,
    $SidebarSectionItem-background-hover: $colour-accent-hover,
    $SidebarSectionItem-background-active: $colour-accent-active,
    $SidebarSectionItem-background-selected: $colour-accent-selected,
    $Sidebar-light-text-colour: $body-text-colour,
    $Panel-header-font-weight: 400,
    $FormField-label-font-family: $body-font-family,
    $DefinitionList-term-font-family: $body-font-family,
    $IconButton-icon-colour: $button-text-colour,
    $IconButton-icon-colour-disabled: color.scale($button-text-colour, $alpha: -80%),
    $SidebarToggle-accented-background: $sidebar-colour,
    $CollapsedSidebar-handle-colour: lighten($body-text-colour, 30),
    $NewLookPanel-background: #fff7d9,
    $LanguageSelectItem-selected-text-colour: $button-text-colour,
    $LanguageSelectItem-selected-background: $secondary-colour,
    $SidebarSectionItem-border-selected: (
        border-left: 5px solid $secondary-colour
    ),
    $Panel-header-padding: 10px 15px,
    $Sidebar-font-family: $heading-font-family,
    $TickBox-unticked-colour: $body-text-colour,
    $TickBox-ticked-colour: #fcb026,
    $TickBox-sidebar-unticked-colour: $body-text-colour,
    $TickBox-sidebar-ticked-colour: #fcb026,
    $notification-text-colour: white,
    $VehicleTabs-font-family: $heading-font-family,
    $VehicleTabs-text-colour-hover: $heading-text-colour,
    $VehicleTabs-text-colour-selected: $heading-text-colour,
    $DocumentLabel-background-new: $secondary-colour,
    $DocumentLabel-text-colour-new: black
);

@use '~@cognitranlimited/itis-web/dist/styles/campaigns';

/** START OF FONTS **/
@import '~@fontsource/oswald/index.css'; // Defaults to weight 400.
@import '~@fontsource/oswald/600.css'; // Defaults to weight 400.
@import '~@fontsource/roboto/index.css'; // Defaults to weight 400.
/** END OF FONTS **/

/**
 * Temporary overrides (these should have a corresponding core case to avoid accumulating styling overrides)
**/

:not(.VehicleHistoryButtons) > {
    .Button,
    a.ButtonLink {
        &,
        &:hover {
            font-family: $heading-font-family;
            font-weight: 600;
            font-stretch: condensed;
            box-shadow: inset 0 1px 0 rgb(255 255 255 / 20%),
                0 1px 2px rgb(0 0 0 / 5%);
        }
    }
}

a:not(.Link-title):not(.Sidebar *):not(.VehicleHistoryList *):not(.VerticalTabBar *):not(.ButtonLink):not(.MuiMenuItem-root):not(.MuiMenuItem-root *) {
    text-decoration: underline;
}

a.MuiTab-textColorPrimary {
    text-decoration: none !important;
}

.DocumentLabel {
    font-family: $heading-font-family;
}
